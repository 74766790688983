import React from 'react'
import {
  Address,
  companyInfo,
  companyName,
  GoogleMap as GoogleMapConfig,
} from '@config/settings'
import Box from '@src/components/atoms/Box'
import HeaderWithImage from '@src/components/molecules/HeaderWithImage'
import GoogleMap from '@src/components/molecules/GoogleMap'
// import { Layout } from '@src/components/layouts'

import '@src/theme/company.sass'

const TableRow = (props: any) => {
  const RowContent =
    props.name === '法人番号' ? (
      <div className="content">
        <a
          href={`http://www.houjin-bangou.nta.go.jp/henkorireki-johoto.html?selHouzinNo=${
            props.content
          }`}
          style={{
            color: 'black',
            textDecoration: 'underline',
          }}
        >
          {props.content}
        </a>
      </div>
    ) : (
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: props.content }}
      />
    )
  return (
    <div className="TableRow">
      <div className="name">{props.name}</div>
      {RowContent}
    </div>
  )
}
const CompanyInfoTable = (props: any) => (
  <div className="CompanyInfoTable">
    {companyInfo[props.locale].map((info: any) => (
      <TableRow {...info} key={info.name} />
    ))}
  </div>
)

export const CompanyScreen = (props: any) => {
  // const style = this.props.$hover ? greatPlaceStyleHover : greatPlaceStyle;

  return (
    <div className="companyPage">
      <HeaderWithImage>
        {/* <h1 className="pageTitle">Company Profile</h1> */}
      </HeaderWithImage>
      <Box>
        <CompanyInfoTable locale={props.locale} />
        <div className="GoogleMapWrapper">
          <GoogleMap
            center={[Address.position.lat, Address.position.lng]}
            zoom={GoogleMapConfig.zoom}
            title={companyName}
          />
        </div>
      </Box>
    </div>
  )
}
